import React from 'react'
import styles from './styles.module.css'
import {NavLink} from 'react-router-dom' 
import { HashLink } from 'react-router-hash-link';//hash link for navigating to specific sections
import {useTranslation} from 'react-i18next'
function Footer() {
  const [t]=useTranslation();//translation hook


  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;//cooordinate of section we want
    const yOffset = -100; //naavigation bar height offset 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); //scroll to section with smooth behaviour
}
  return (
    <div className={styles.footerBackground}>
      <div className={`container ${styles.footerContainer}`}>
        <div>
          <h4>
            <NavLink to="/">{t("SOLUTIONS")}</NavLink>
          </h4>
          <ul>
            <li><HashLink to="" >ROBOSCOUT</HashLink></li>
            <li><HashLink smooth to="/page#connect" scroll={el => scrollWithOffset(el)}>{t("SHAGARAH")}</HashLink></li>
            <li><HashLink smooth to="/page#see-yourself" scroll={el => scrollWithOffset(el)}>{t("SEE YOURSELF")}</HashLink></li>
          </ul>
        </div>
        <div>
          <h4>
            <NavLink to="/how-it-works">{t("HOW IT WORKS")}</NavLink>
          </h4>
          <ul>
            <li><NavLink to="/how-it-works">{t("HOW IT WORKS")}</NavLink></li>
          </ul>
        </div>
        <div>
          <h4>
            <NavLink to="about-us">{t("ABOUT")}</NavLink>
          </h4>
          <ul>
            <li><HashLink smooth to="about-us/page#story" scroll={el => scrollWithOffset(el)}>{t("STORY")}</HashLink></li>
            <li><HashLink smooth to="about-us/page#company" scroll={el => scrollWithOffset(el)}>{t("LEADERSHIP")}</HashLink></li>
            <li><HashLink smooth to="about-us/page#values" scroll={el => scrollWithOffset(el)}>{t("VALUES")}</HashLink></li>
          </ul>
        </div>
        <div>
          <h4>
            <NavLink to="contact-us">{t("CONTACT US")}</NavLink>
          </h4>
          <p style={{color:"var(--headings-color)",fontWeight:"700"}}>{t("EGYPT")}</p>
          <p>{t("Cairo, New Cairo,1345")}</p>
          <p>{t("Alexandria,Ibrahimeyah,4537")}</p>
          <p>{t("+20-155-3718894")}<br/>info@egrobots.com</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
