import React,{useState,useRef,useEffect} from 'react'
import styles from './styles.module.css'
//react icons
import { MdOutlineLanguage } from "react-icons/md";
import { GiHamburgerMenu} from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { MdOutlineArrowDropDown } from "react-icons/md";
import {NavLink,useNavigate,useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import logo from '../../../assets/HEADER/logo.png'
import new_logo from '../../../assets/HEADER/new_logo.png'
import MediaQuery from 'react-responsive'; //library for rendering component and tags based on media size
import Solutions from "../../Solutions";

function Header() {
  const [colorChange, setColorchange] = useState(false); //change color of navbar when scrolling
  const [showLinks, setShowLinks] = useState(false); //show and hide navigation links on mobiles
  const [showlangMenu, setShowLangMenu] = useState(false); ////show and hide language links on mobiles
  const header = useRef(null); //header reference
  const linksContainer = useRef(null); //ref for container div of links
  const links = useRef(null); //ref to links
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation(); //translation hook

  //change height of links container drop down menu according to height of links when show links state is true otherwise height is zero
  const checkMenuHeight = () => {
    const height = links.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainer.current.style.height = `${height}px`;
      //change navbar background color when opened
      header.current.style.backgroundColor = "var(--bg-color)";
    } else {
      linksContainer.current.style.height = "0px";
      if (window.scrollY <= 100) {
        //make navabar transparent if y scrolling is less than height of navbar
        header.current.style.backgroundColor = "transparent";
      }
    }
  };

  //change height when change on showLinks state
  useEffect(() => {
    checkMenuHeight();
  }, [showLinks]);

  //change height when change on showlangMenu state
  useEffect(() => {
    checkMenuHeight();
  }, [showlangMenu]);

  //close menu when pathname changes or language changes
  useEffect(() => {
    setShowLinks(false);
    setShowLangMenu(false);
  }, [pathname, i18n.language]);

  //if scrolling height is > navbar height set colorChange state to true else false
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const languageHandle = (lang) => {
    if (lang == "ar") {
      document.body.classList.add("rtl");
      document.body.style.direction = "rtl";
      i18n.changeLanguage(lang);
    } else {
      document.body.classList.remove("rtl");
      document.body.style.direction = "ltr";
      i18n.changeLanguage(lang);
    }
  };

  return (
    <>
      <NavLink
        to="contact-us"
        className={styles.demoRequest}
        style={{ borderRadius: "0", backgroundColor: "var(--headings-color)" }}
      >
        {t("REQUEST A DEMO")}
      </NavLink>
      {/*color navbar if changeColor set to true else make it transparent */}
      <header
        className={styles.header}
        style={{
          backgroundColor: colorChange ? "var(--nav-color)" : "transparent",
        }}
        ref={header}
      >
        <div className={`container ${styles.headerContainer}`}>
          <div className={styles.logoMenu}>
            <div className={styles.logoContainer}>
              <img src={new_logo} alt="logo" className={styles.logo} />
              {/* <h2>{t('EGROBOTS')}</h2> */}
            </div>
            <button
              className={styles.toggler}
              onClick={() => setShowLinks(!showLinks)}
            >
              {!showLinks ? <GiHamburgerMenu /> : <IoClose />}
            </button>
          </div>
          <div className={styles.linksContainer} ref={linksContainer}>
            <ul className={styles.links} ref={links}>
              <li className={styles.menuItem}>
                <NavLink
                  to="/#solutions"
                >
                  {t("SOLUTIONS")}
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink to="how-it-works">{t("HOW IT WORKS")}</NavLink>
              </li>
              <li className={styles.menuItem} style={{ display: "none" }}>
                <NavLink to="contact-us">{t("CONTACT US")}</NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink to="about-us">{t("ABOUT")}</NavLink>
              </li>
              <MediaQuery query="(max-device-width: 1023px)">
                <li
                  className={`${styles.menuItem}`}
                  style={{ fontWeight: "bold" }}
                >
                  <NavLink
                    to="#"
                    onClick={() => {
                      setShowLangMenu(!showlangMenu);
                    }}
                  >
                    {t("LANGUAGE")}
                    <MdOutlineArrowDropDown />
                  </NavLink>
                  {/* change language small screens*/}
                  {showlangMenu && (
                    <ul className={styles.subMenu}>
                      <li
                        onClick={() => languageHandle("ar")}
                        style={{ fontFamily: "Almarai, sans-serif" }}
                      >
                        العربية
                      </li>
                      <li
                        onClick={() => languageHandle("en")}
                        style={{ fontFamily: "Montserrat,sans-serif" }}
                      >
                        ENGLISH
                      </li>
                    </ul>
                  )}
                </li>
              </MediaQuery>
            </ul>
          </div>
          <div className={styles.operations}>
            <button
              className={`action ${styles.lang}`}
              style={{ color: "var(--headings-color)", position: "relative" }}
            >
              <MdOutlineLanguage />
              {/* change language large screens*/}
              <ul className={styles.langMenu}>
                {i18n.language != "ar" && (
                  <li
                    style={{
                      cursor: "pointer",
                      fontFamily: "Almarai, sans-serif",
                    }}
                    onClick={() => languageHandle("ar")}
                  >
                    العربية
                  </li>
                )}
                {i18n.language != "en" && (
                  <li
                    style={{
                      cursor: "pointer",
                      fontFamily: "Montserrat,sans-serif",
                    }}
                    onClick={() => languageHandle("en")}
                  >
                    ENGLISH
                  </li>
                )}
              </ul>
            </button>
            <button
              onClick={() => navigate("contact-us")}
              className="btn"
              style={{ backgroundColor: "#e49621", color: "var(--bgs-wcolor)" }}
            >
              {t("CONTACT US")}
            </button>
          </div>
        </div>
      </header>

      {/* title displayed on header */}
      <div className={styles.landing}>
        <div className="overlay"></div>
        <div className={styles.text}>
          <MediaQuery query="(max-device-width: 1023px)">
            <h2 style={{ letterSpacing: "2px" }}>{t("AI-DRIVEN INSIGHTS")}</h2>
            <h2>{t("LEAF-LEVEL PRECISION")}</h2>
            <p
              style={{
                fontSize: "1rem",
                color: "var(--headings-color)",
                fontWeight: "700",
              }}
            >
              {t("CLOUD-BASED CONVENIENCE")}
            </p>
          </MediaQuery>
          <MediaQuery query="(min-device-width: 1024px)">
            <h1 style={{ letterSpacing: "3px" }}>{t("AI-DRIVEN INSIGHTS")}</h1>
            <h1>{t("LEAF-LEVEL PRECISION")}</h1>
            <p
              style={{
                fontSize: "1.4rem",
                color: "#e49621",
                fontWeight: "1000",
              }}
            >
              {t("CLOUD-BASED CONVENIENCE")}
            </p>
          </MediaQuery>
        </div>
      </div>
    </>
  );
}

export default Header
