import './App.css';
import React, { useRef } from "react";
import {Route,Routes} from 'react-router-dom'
import Header from './components/shared/Header'
import Solutions from './components/Solutions';
import Footer from './components/shared/Footer';
import HOWITWORKS from './components/HOWITWORKS';
import ABOUT from './components/ABOUT';
import CONTACTUS from './components/CONTACTUS';
function App() {
  const ref = useRef(null);
  return (
    // components routes
    <>
      <Header/>
      <div ref={ref}>
        <Routes>
          <Route
            path="/"
            element={<Solutions />}
          ></Route>
          <Route path="how-it-works" element={<HOWITWORKS />}></Route>
          <Route path="about-us" element={<ABOUT />}></Route>
          <Route path="contact-us" element={<CONTACTUS />}></Route>
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
