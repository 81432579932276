import React,{useState} from 'react'
//mui accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MdDone,MdOutlineClose } from "react-icons/md"; //react icons 
import {useTranslation} from 'react-i18next'
import styles from './styles.module.css'
import MediaQuery from 'react-responsive' //render components and tags based on media query


function Accordionn() {
  const[t]=useTranslation(); //translation hook
  const [expanded, setExpanded] = React.useState('panel1');//expand first panel by default
  const [tabIndex,setTabIndex]=useState(0); //for changing active tab on mobiles

  //method for expanding or closing default opened panel
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //styling accordion method based on documentation of mui library
  const accordionStyles={
    borderRadius:"0 !important",
    border:0,
    '& .MuiAccordionSummary-content':{
      order:2,
      
    },
    '& .Muidiv-root':{
      fontWeight:'bold'
    },
    '& .MuiSvgIcon-root':{
      fontSize:'3rem'
    },
    '& .MuiAccordionSummary-expandIconWrapper':{
      color:"var(--headings-color)",
      order:1
    },
    '& .MuiAccordionDetails-root':{
      padding:"0 !important"
    }
  }
  return (
    <>
      {/* render in media where minimum width 768px */}
      <MediaQuery query="(min-device-width: 768px)">
        <tr>
          <td colSpan="5">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>
                {t("Broadacre Monitoring")}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <table style={{tableCollapse:"collapse"}}>
                  <tbody>
                    <tr>
                      <td rowSpan="5" className={styles.widerCell}>{t("Delivers whole farm visibility for more precise and effective management & planning.")}</td>
                      <td className={styles.widerCell}>{t("Standard Satellite Layers")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Field Health & Anomaly Alerts")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Weather & Precipitation")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Premium Satellite Layers")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    {/* <tr>
                      <td className={styles.widerCell}>{t("MacroView Perspective")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr> */}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            <Accordion  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>{t("AI-Enabled Leaf-Level Insights")}</div>
              </AccordionSummary>
              <AccordionDetails>
              <table>
                <tbody>
                  <tr>
                    <td rowSpan="4" className={styles.widerCell}>{t("AI-enabled, leaf-level insights provide unsurpassed resolution and unmistakable insights, eliminating guesswork and enabling faster, more effective action.")}</td>
                    <td className={styles.widerCell}>{t("Early Season Insights")}<br/>{t("Stand Count & Weed Severity")}</td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                  {/* <tr>
                    <td className={styles.widerCell}>{t("Stand Count Zone Creation")}</td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr> */}
                  <tr>
                    <td className={styles.widerCell}>{t("Midseason Insights & Threat Severity")}<br/>{t("Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect")}</td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                  <tr>
                    <td className={styles.widerCell}>{t("Full Season Insights & Threat Severity")}<br/>{t("Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect")}</td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                </tbody>
              </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            <Accordion  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>
                {t("Insight-Driven Engagement")}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <table style={{tableCollapse:"collapse"}}>
                  <tbody>
                    <tr>
                      <td rowSpan="3" className={styles.widerCell}>{t("Insight-driven engagement with AI-enabled, fact-based knowledge means fast, action-oriented engagement anywhere, anytime.")} </td>
                      <td className={styles.widerCell}>{t("AI-Directed Scouting")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Grower & Retailer Access")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Grower Engagement Analytics")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            <Accordion  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>
                {t("Insight-Driven Planning")}
                </div>
              </AccordionSummary>
              <AccordionDetails>
              <table style={{tableCollapse:"collapse"}}>
                <tbody>
                  <tr>
                    <td rowSpan="4" className={styles.widerCell}>{t("Good plans start with great data. Plan better with multi-level, multi-season data to inform field analysis and practical action all year long.")}</td>
                    <td className={styles.widerCell}>{t("Season-in-Review Analysis & Planning")}</td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                  <tr>
                    <td className={styles.widerCell}>{t("Secured Historic Data Access (3 years)")}</td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}><MdDone/></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                  <tr>
                    <td className={styles.widerCell}>{t("Secured Historic Data Access (Unlimited)")}</td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                  <tr>
                    <td className={styles.widerCell}>{t("True Yield Impact")}</td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}></td>
                    <td className={styles.rightSign}><MdDone/></td>
                  </tr>
                </tbody>
              </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            <Accordion  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>{t("Enhanced Grower Experience")}</div>
              </AccordionSummary>
              <AccordionDetails>
                <table style={{tableCollapse:"collapse"}}>
                  <tbody>
                    <tr>
                      <td rowSpan="3" className={styles.widerCell}>{t("Enhanced grower experience through retailer-specific capabilities and support.")}</td>
                      <td className={styles.widerCell}>{t("Custom-branded Mobile Application")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Joint-Marketing Activities")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Grower Seminars")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            <Accordion  sx={accordionStyles}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <div style={{ fontWeight:"700",width: '33%', flexShrink: 0 }}>{t("Year-Round Service and Support")}</div>
              </AccordionSummary>
              <AccordionDetails>
                <table>
                  <tbody>
                    <tr>
                      <td rowSpan="6" className={styles.widerCell}>{t("Year-round service and support means we’re never part-time partners. We’re part of your team every farm, every day.")}</td>
                      <td className={styles.widerCell}>{t("Full-Service, Cloud-Based Convenience")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Virtual Training & Knowledge Center")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Essential Customer Success Support")}</td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Premier Customer Success Support")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("On-site Training")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                    <tr>
                      <td className={styles.widerCell}>{t("Advisory Board Membership")}</td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}></td>
                      <td className={styles.rightSign}><MdDone/></td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
      </MediaQuery>




      {/* render in media where minimum width 768px */}
      <MediaQuery query="(max-device-width: 767px)">
        <div style={{padding:"0 0.5rem"}}>
          {/* buttons for changing tab on mobile */}
          <div className={styles.sAccordionTabs}>
            <button className={tabIndex==0?`${styles.activeTab}`:''} onClick={()=>setTabIndex(0)}>ESSENTIAL</button>
            <button className={tabIndex==1?`${styles.activeTab}`:''} onClick={()=>setTabIndex(1)}>ADVANCED</button>
            <button className={tabIndex==2?`${styles.activeTab}`:''} onClick={()=>setTabIndex(2)}>PRO</button>
          </div>
          <div style={{display:tabIndex==0?'block':'none'}}>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Broadacre Monitoring")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Delivers whole farm visibility for more precise and effective management & planning.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Standard Satellite Layers</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Field Health & Anomaly Alerts</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Weather & Precipitation</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Premium Satellite Layers</li>
                      {/* <li className={styles.disabled}><span><MdOutlineClose/></span>MacroView Perspective</li> */}
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <div style={{ width: '100%'}}>{t("AI-Enabled Leaf-Level Insights")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    AI-enabled, leaf-level insights provide unsurpassed resolution and unmistakable insights, eliminating guesswork and enabling faster, more effective action.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Early Season Insights-Stand Count & Weed Severity</li>
                      {/* <li><span className={styles.rightSign}><MdDone /></span>Stand Count Zone Creation</li> */}
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Midseason Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Full Season Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Engagement")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Insight-driven engagement with AI-enabled, fact-based knowledge means fast, action-oriented engagement anywhere, anytime.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>AI-Directed Scouting</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Grower & Retailer Access</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Grower Engagement Analytics</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8bh-content"
                  id="panel8bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Planning")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Good plans start with great data. Plan better with multi-level, multi-season data to inform field analysis and practical action all year long.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Season-in-Review Analysis & Planning</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Secured Historic Data Access (3 years)</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Secured Historic Data Access (Unlimited)</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>True Yield Impact</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9bh-content"
                  id="panel9bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Enhanced Grower Experience")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Enhanced grower experience through retailer-specific capabilities and support.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Custom-branded Mobile Application</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Joint-Marketing Activities</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Grower Seminars</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10bh-content"
                  id="panel10bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Year-Round Service and Support")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Year-round service and support means we’re never part-time partners. We’re part of your team every farm, every day
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Full-Service, Cloud-Based Convenience</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Virtual Training & Knowledge Center</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Essential Customer Success Support</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Premier Customer Success Support</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>On-site Training</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Advisory Board Membership</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
          </div>
          <div style={{display:tabIndex==1?'block':'none'}}>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11bh-content"
                  id="panel11bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Broadacre Monitoring")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Delivers whole farm visibility for more precise and effective management & planning.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Standard Satellite Layers</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Field Health & Anomaly Alerts</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Weather & Precipitation</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Premium Satellite Layers</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>MacroView Perspective</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12bh-content"
                  id="panel12bh-header"
                >
                  <div style={{ width: '100%'}}>{t("AI-Enabled Leaf-Level Insights")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    AI-enabled, leaf-level insights provide unsurpassed resolution and unmistakable insights, eliminating guesswork and enabling faster, more effective action.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Early Season Insights-Stand Count & Weed Severity</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Stand Count Zone Creation</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Midseason Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Full Season Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel13bh-content"
                  id="panel13bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Engagement")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Insight-driven engagement with AI-enabled, fact-based knowledge means fast, action-oriented engagement anywhere, anytime.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>AI-Directed Scouting</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Grower & Retailer Access</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Grower Engagement Analytics</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel14bh-content"
                  id="panel14bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Planning")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Good plans start with great data. Plan better with multi-level, multi-season data to inform field analysis and practical action all year long.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Season-in-Review Analysis & Planning</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Secured Historic Data Access (3 years)</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Secured Historic Data Access (Unlimited)</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>True Yield Impact</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel15bh-content"
                  id="panel15bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Enhanced Grower Experience")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Enhanced grower experience through retailer-specific capabilities and support.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Custom-branded Mobile Application</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Joint-Marketing Activities</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Grower Seminars</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel16bh-content"
                  id="panel16bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Year-Round Service and Support")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Year-round service and support means we’re never part-time partners. We’re part of your team every farm, every day
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Full-Service, Cloud-Based Convenience</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Virtual Training & Knowledge Center</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Essential Customer Success Support</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Premier Customer Success Support</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>On-site Training</li>
                      <li className={styles.disabled}><span><MdOutlineClose/></span>Advisory Board Membership</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
          </div>
          <div style={{display:tabIndex==2?'block':'none'}}>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel17bh-content"
                  id="panel17bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Broadacre Monitoring")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Delivers whole farm visibility for more precise and effective management & planning.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Standard Satellite Layers</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Field Health & Anomaly Alerts</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Weather & Precipitation</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Premium Satellite Layers</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>MacroView Perspective</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel18bh-content"
                  id="panel18bh-header"
                >
                  <div style={{ width: '100%'}}>{t("AI-Enabled Leaf-Level Insights")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    AI-enabled, leaf-level insights provide unsurpassed resolution and unmistakable insights, eliminating guesswork and enabling faster, more effective action.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Early Season Insights-Stand Count & Weed Severity</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Stand Count Zone Creation</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Midseason Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                      <li className={styles.disabled}><span className={styles.rightSign}><MdDone /></span>Full Season Insights & Threat Severity-Weeds, Disease, Nutrient Deficiencies, Defoliation & Insect</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Engagement")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Insight-driven engagement with AI-enabled, fact-based knowledge means fast, action-oriented engagement anywhere, anytime.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>AI-Directed Scouting</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Grower & Retailer Access</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Grower Engagement Analytics</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Insight-Driven Planning")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Good plans start with great data. Plan better with multi-level, multi-season data to inform field analysis and practical action all year long.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Season-in-Review Analysis & Planning</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Secured Historic Data Access (3 years)</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Secured Historic Data Access (Unlimited)</li>
                      <li><span className={styles.rightSign}><MdDone /></span>True Yield Impact</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel21bh-content"
                  id="panel21bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Enhanced Grower Experience")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Enhanced grower experience through retailer-specific capabilities and support.
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Custom-branded Mobile Application</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Joint-Marketing Activities</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Grower Seminars</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
            <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel22bh-content"
                  id="panel22bh-header"
                >
                  <div style={{ width: '100%'}}>{t("Year-Round Service and Support")}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.sAccordion}>
                    <p>
                    Year-round service and support means we’re never part-time partners. We’re part of your team every farm, every day
                    </p>
                    <ul>
                      <li><span className={styles.rightSign}><MdDone /></span>Full-Service, Cloud-Based Convenience</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Virtual Training & Knowledge Center</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Essential Customer Success Support</li>
                      <li ><span className={styles.rightSign}><MdDone /></span>Premier Customer Success Support</li>
                      <li><span className={styles.rightSign}><MdDone /></span>On-site Training</li>
                      <li><span className={styles.rightSign}><MdDone /></span>Advisory Board Membership</li>
                    </ul>
                  </div>
                </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default Accordionn
