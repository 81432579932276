import React, { useRef, useState, useEffect } from "react";
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import banner from '../../assets/CONTACTUS/banner.jpg'
import emailjs from 'emailjs-com'
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";


function CONTACTUS() {
  const [done, setDone] = useState(false);//form submission indicator for user
  const [cfirstName, setcfirstName] = useState("");
  const [clastName, setclastName] = useState("");
  const [cemail, setcemail] = useState("");
  const [cphone, setcphone] = useState("");
  const [cacres, setcacres] = useState("");
  const [crole, setcrole] = useState("");
  const [ccrop, setccrop] = useState("");
  const [t] = useTranslation();
  const form = useRef(null);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      var element = ref.current;
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs library method to send email from client side
    emailjs.sendForm("service_t4ave8o", "template_i9dac0o", form.current, "Er8tV8VB4WJGhllht")
      .then(res => { form.current.reset(); setDone(true) })//set form as form is submitted
      .catch(e => console.log(e))
  }
  
  useEffect(() => {
    if (done) {
      const timeOut = setTimeout(() => {
        setDone(false) //hide submission indicator after 3 seconds
      }, 3000)
      return () => clearInterval(timeOut)
    }
  }, [done])

  const getSelectedJob = () => {
    var name = document.getElementById("job");
    var uRole = name.options[name.selectedIndex].text;
    setcrole(uRole);
    // var value = name.value;
  };
  
  const addContact = (e) => {
    e.preventDefault();
    axios
      .post(`https://egrobots.com/egrocare-api/egrocare/contact`, {
        firstName: cfirstName,
        lastName: clastName,
        email: cemail,
        phoneNumber: cphone,
        acres: cacres,
        userRole: crole,
        crop: ccrop,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) setDone(true);
      });
    setcfirstName("");
    setclastName("");
    setcemail("");
    setcrole("");
    setcphone("");
    setcacres("");
  };
  const handleCrops=(e)=>{
      if(e.target.checked===true)
      setccrop(ccrop.concat(e.target.value).concat(" , "));
      if(e.target.checked===false)
      setccrop(ccrop.replace(e.target.value,""));
  }
  
  return (
    <div
      className={`container ${styles.contactContainer}`}
      style={{ color: "var(--main-color)" }}
      ref={ref}
    >
      <div>
        <p>
          {t(
            "To schedule a demo, or to speak with a Egrobots representative, please complete the form below and we’ll contact you shortly."
          )}
        </p>
        <form onSubmit={(e) => addContact(e)} ref={form}>
          <fieldset>
            <div>
              <label htmlFor="firstName">
                {t("first name")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="firstName"
                value={cfirstName}
                onChange={(e) => setcfirstName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="lastName">
                {t("last name")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="lastName"
                value={clastName}
                onChange={(e) => setclastName(e.target.value)}
              />
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label htmlFor="email">
                {t("email")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="email"
                value={cemail}
                onChange={(e) => setcemail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="phoneNumber">
                {t("phone number")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="phoneNumber"
                value={cphone}
                onChange={(e) => setcphone(e.target.value)}
              />
            </div>
          </fieldset>
          <fieldset>
            <label htmlFor="job">{t("i am a")}</label>
            <select name="job" onChange={getSelectedJob} id="job">
              <option value="Agronomy Advisor">{t("Agronomy Advisor")}</option>
              <option value="Ag Retailer">{t("Ag Retailer")}</option>
              <option value="Grower">{t("Grower")}</option>
              <option value="Other">{t("Other")}</option>
            </select>
          </fieldset>
          <fieldset>
            <label htmlFor="acresNumber">
              {t("If you are a grower, how many acres do you farm?")}
            </label>
            <input
              type="number"
              name="acresNumber"
              value={cacres}
              onChange={(e) => setcacres(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="corps">{t("Which crops do you farm?")}</label>
            <div>
              <input
                type="checkbox"
                name="mango"
                value="Mango"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="mango">{t("Mango")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="palm"
                value="Palm"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="Palm">{t("Palm")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="apple"
                value="Apple"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Apple")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="corn"
                value="Corn"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Corn")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="grape"
                value="Grape"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Grape")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="pepper"
                value="Peper"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Peper")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="strawberry"
                value="Strawberry"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Strawberry")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="tomato"
                value="Tomato"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Tomato")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="citrus"
                value="Citrus"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Citrus")}</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="other"
                value="Other"
                onChange={(e) => handleCrops(e)}
              />
              <label htmlFor="">{t("Other")}</label>
            </div>
          </fieldset>
          <div>
            <button
              style={{
                marginRight: "0.5rem",
                backgroundColor: "var(--headings-color)",
                width: "fit-content",
                color: "white",
                padding: "0.5rem",
                cursor: "pointer",
                border: "0",
              }}
            >
              {t("Schedule Demo")}
            </button>
            {done && (
              <FaCheckCircle
                style={{ color: "var(--headings-color)", fontSize: "1.2rem" }}
              />
            )}
          </div>
        </form>
      </div>
      <div>
        <div>
          <h3 style={{ color: "var(--headings-color)" }}>{t("CONTACT US")}</h3>
          <h4>
            <strong style={{ color: "var(--headings-color)" }}>
              {t("CALL")}
            </strong>{" "}
            {t("+20-155-3718894")}
          </h4>
          <h4>
            <strong style={{ color: "var(--headings-color)" }}>
              {t("EMAIL")}
            </strong>{" "}
            info@egrobots.com
          </h4>
        </div>
        <img src={banner} alt="contact-banner" />
      </div>
    </div>
  );
}

export default CONTACTUS
