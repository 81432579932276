import React, { useRef, useEffect } from "react";
import styles from'./styles.module.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaLightbulb,FaHandsHelping,FaUsers } from "react-icons/fa";
import { BsFillSuitHeartFill} from "react-icons/bs";
import {useTranslation} from 'react-i18next'
import member1 from '../../assets/ABOUT/members/member1.jpg'
import member2 from '../../assets/ABOUT/members/member2.jpg'
import member3 from '../../assets/ABOUT/members/member3.jpg'
import member4 from '../../assets/ABOUT/members/member4.jpg'
import member5 from '../../assets/ABOUT/members/member5.jpg'
import member6 from '../../assets/ABOUT/members/member6.jpg'
import member7 from '../../assets/ABOUT/members/member7.jpg'
import story from '../../assets/ABOUT/story.jpg'


function ABOUT() {
  const[t]=useTranslation(); //translation hook
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      var element = ref.current;
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  //our values carousel number of images in different screen sizes
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you. (superLargeDesktop,desktop,...)
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1023, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  
  //leaership carousel number of images in different screen sizes
  const responsiveM = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };


  return (
    <div style={{ color: "var(--wgrey)" }} ref={ref}>
      {/* story section */}
      <p className="landingTitle" style={{ marginBottom: "1rem" }} id="story">
        {t("OUR STORY")}
      </p>
      <section
        style={{
          backgroundColor: "var(--bg-lgrey)",
          padding: "2rem 0",
          marginBottom: "2rem",
        }}
      >
        <div className={`container ${styles.storyContainer}`}>
          <div
            style={{
              maxWidth: "fit-content",
              borderTop: "8px solid var(--headings-color)",
            }}
          >
            <img
              src={story}
              alt="story"
              style={{ filter: "grayscale(100%)", maxWidth: "100%" }}
            />
          </div>
          <div>
            <p>
              {t(
                "Today’s agricultural landscape is changing rapidly. As farm sizes and demands upon growers increase, they need more, and more accurate information – and they need it fast."
              )}
            </p>
            <p>
              {t(
                "Established in 2021, Egrobots marries a deep respect and understanding of farm heritage with agronomic expertise and cutting-edge technology. Using state-of-the-art multi-level high-resolution imagery and revolutionary AI and ML capabilities, we give advisors, suppliers and growers insights with unsurpassed clarity and indisputable insights."
              )}
            </p>
            <p>
              {t(
                "We enable today’s advisors and growers to cover more acres and yet take action with surgical precision. This means more strategic use of products improving not just plant health and yield but stewardship and sustainability."
              )}
            </p>
          </div>
        </div>
      </section>

      {/* leaership section */}
      <section id="company">
        <p className="landingTitle" style={{ marginBottom: "1rem" }}>
          {t("LEADERSHIP TEAM")}
        </p>
        <div
          className={`container ${styles.teamCarouselContainer}`}
          style={{ position: "relative" }}
        >
          {/* show dots of images - autoPlay carousel - infinite play - dots outside carousel - speed of carousek */}
          <Carousel
            containerClass={styles.carouselContainer}
            responsive={responsiveM}
            showDots={true}
            autoPlay={true}
            infinite={true}
            renderDotsOutside={true}
            dotListClass={styles.dot}
            autoPlaySpeed={5000}
            transitionDuration={500}
          >
            <div>
              <img src={member1} alt="member" />
            </div>
            <div>
              <img src={member2} alt="member" />
            </div>
            <div>
              <img src={member3} alt="member" />
            </div>
            <div>
              <img src={member4} alt="member" />
            </div>
            <div>
              <img src={member5} alt="member" />
            </div>
            <div>
              <img src={member6} alt="member" />
            </div>
            <div>
              <img src={member7} alt="member" />
            </div>
          </Carousel>
        </div>
      </section>

      {/* values section */}
      <section id="values">
        <div
          className="container"
          style={{ marginBottom: "2rem", position: "relative" }}
        >
          <p
            className="landingTitle"
            style={{ backgroundColor: "var(--main-color)" }}
          >
            {t("OUR VALUES")}
          </p>
          {/* hide partial visibility of card item */}
          <Carousel
            draggable={false}
            itemClass="item"
            dotListClass={styles.dot}
            renderDotsOutside={true}
            responsive={responsive}
            partialVisbile={false}
            showDots={true}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={5000}
            transitionDuration={500}
            containerClass="carousel-container"
          >
            <div className={styles.carouselCard}>
              <div>
                <div className={styles.carouselIcon}>
                  <FaUsers />
                </div>
              </div>
              <div>
                <h3>{t("TEAMWORK & COLLABORATION")}</h3>
                <p>
                  {t(
                    "Collaboration with Ag retailers, suppliers, technology partners and each other always means stronger, more effective results for growers bringing together diverse people, perspectives and the power of innovation."
                  )}
                </p>
              </div>
            </div>
            <div className={styles.carouselCard}>
              <div>
                <div className={styles.carouselIcon}>
                  <FaHandsHelping />
                </div>
              </div>
              <div>
                <h3>{t("PASSION & PURPOSE")}</h3>
                <p>
                  {t(
                    "We believe technology and data will continue to transform agriculture and are fiercely focused and committed to advancing the use of AI and Machine Learning to improve on-farm performance."
                  )}
                </p>
              </div>
            </div>
            <div className={styles.carouselCard}>
              <div>
                <div className={styles.carouselIcon}>
                  <FaLightbulb />
                </div>
              </div>
              <div>
                <h3>{t("TRUST & STEWARDSHIP")}</h3>
                <p>
                  {t(
                    "Trust is a prerequisite for collaboration. Our products build trust between advisors and growers while as a team we operate with integrity, practicality and transparency in all that we do."
                  )}{" "}
                </p>
              </div>
            </div>
            <div className={styles.carouselCard}>
              <div>
                <div className={styles.carouselIcon}>
                  <BsFillSuitHeartFill />
                </div>
              </div>
              <div>
                <h3>{t("INNOVATION & PERSISTENCE")}</h3>
                <p>
                  {t(
                    "We are insatiably curious and driven innovators. With a strong respect for farming heritage, we strive to find better ways to create value for retailers, advisors and growers."
                  )}{" "}
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default ABOUT
